import React, {
  createContext,
  Dispatch,
  useReducer,
  useContext,
  useEffect,
} from 'react';
import { ContractorInfo, CustomerInfo, Role } from '../types';
import { auth } from '../firebase/config';
import { snapshotToDoc, contractorsRef } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { User as FirebaseUser } from 'firebase/auth';
import { computeSubscriptionStatus } from './auth-context-util';

interface Props {
  children: React.ReactNode;
}

export type ContractorAuthUser = {
  contractorId: string;
  email: string | null;
};

type AuthState = {
  authUser: ContractorAuthUser | null;
  userInfo: ContractorInfo | CustomerInfo | null;
  userRole: Role | null;
};
const AuthStateContext = createContext<AuthState | undefined>(undefined);

type FETCH_AUTH_USER = {
  type: 'FETCH_AUTH_USER';
  payload: ContractorAuthUser | null;
};
type FETCH_USER_INFO = {
  type: 'FETCH_USER_INFO';
  payload: ContractorInfo | CustomerInfo | null;
};
type SET_USER_ROLE = { type: 'SET_USER_ROLE'; payload: Role | null };

type AuthActions = FETCH_AUTH_USER | FETCH_USER_INFO | SET_USER_ROLE;
type AuthDispatch = Dispatch<AuthActions>;
const AuthDispatchContext = createContext<AuthDispatch | undefined>(undefined);

// Action creators
export const fetchAuthUser = (
  user: ContractorAuthUser | null
): FETCH_AUTH_USER => ({
  type: 'FETCH_AUTH_USER',
  payload: user,
});

export const fetchUserInfo = (
  userInfo: ContractorInfo | CustomerInfo | null
): FETCH_USER_INFO => ({
  type: 'FETCH_USER_INFO',
  payload: userInfo,
});

export const setUserRole = (role: Role | null): SET_USER_ROLE => ({
  type: 'SET_USER_ROLE',
  payload: role,
});

// Auth Reducer function initial state
const initialState: AuthState = {
  authUser: null,
  userInfo: null,
  userRole: null,
};

// Auth Reducer function
const authReducer = (state: AuthState, action: AuthActions): AuthState => {
  switch (action.type) {
    case 'FETCH_AUTH_USER':
      return {
        ...state,
        authUser: action.payload,
      };
    case 'FETCH_USER_INFO':
      return {
        ...state,
        userInfo: action.payload,
      };
    case 'SET_USER_ROLE':
      return {
        ...state,
        userRole: action.payload,
      };
    default:
      return state;
  }
};

const AuthContextProvider: React.FC<Props> = ({ children }: Props) => {
  const [authState, authDispatch] = useReducer(authReducer, initialState);

  // listen for auth state changes and set user role and user info
  useEffect(() => {
    // Handle authenticated user
    const handleAuthenticatedUser = async (user: FirebaseUser) => {
      try {
        const result = await user.getIdTokenResult();
        const role = result.claims.role as Role;

        authDispatch(setUserRole(role));
        authDispatch(
          fetchAuthUser({ contractorId: user.uid, email: user.email })
        );
      } catch (error) {
        // Handle error getting token
        authDispatch(setUserRole('DEFAULT'));
        authDispatch(fetchAuthUser(null));
      }
    };

    // Handle unauthenticated user
    const handleUnauthenticatedUser = () => {
      authDispatch(setUserRole('DEFAULT'));
      authDispatch(fetchAuthUser(null));
    };

    // Listener for auth state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        handleAuthenticatedUser(user);
      } else {
        handleUnauthenticatedUser();
      }
    });

    // Proper cleanup function - will be called when component unmounts
    return () => unsubscribe();
  }, []);

  // Listen to user document changed in firestore
  useEffect(() => {
    let isMounted = true; // Track component mount status

    const fetchUserInfoAsync = async (): Promise<void> => {
      // Case 2 - user logs out or not authenticated
      if (!authState.authUser) {
        // Before updating state after async operations, we verify the component is still mounted
        if (isMounted) {
          authDispatch(fetchUserInfo(null));
        }
        return;
      }

      // Case 1 - user logs in
      try {
        // (a) Fetch user info
        const docRef = doc(contractorsRef, authState.authUser.contractorId);
        const snapshot = await getDoc(docRef);

        // Check if still relevant after async operation
        if (!isMounted) return;

        // (c) Error fetching user info
        // Force a proper logout through Firebase
        // cannot proceed without user info
        if (!snapshot.exists()) {
          authDispatch(fetchUserInfo(null));
          await auth.signOut();
          return;
        }

        // (b) Contractor document exists no error fetching => proceed to fetch user info
        // takes a Firestore document snapshot as input and returns an object of type UserInfo.
        const userInfo = snapshotToDoc<ContractorInfo>(snapshot);
        // Compute subscription status if subscription info exists
        if (userInfo.subscriptionInfo) {
          userInfo.subscriptionInfo.computedStatus = computeSubscriptionStatus(
            userInfo.subscriptionInfo
          );
        }
        authDispatch(fetchUserInfo(userInfo));
      } catch {
        if (isMounted) {
          authDispatch(fetchUserInfo(null));
          await auth.signOut();
        }
        return; // Return immediately to prevent continuing if an error occurs fetching user info
      }
      // END Case 1 - user logs in
    };

    fetchUserInfoAsync();

    // Cleanup function to prevent memory leaks
    return () => {
      isMounted = false;
    };
  }, [authState.authUser]);

  return (
    <AuthStateContext.Provider value={authState}>
      <AuthDispatchContext.Provider value={authDispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export default AuthContextProvider;

export const useAuthContext = () => {
  const authState = useContext(AuthStateContext);
  const authDispatch = useContext(AuthDispatchContext);

  if (authState === undefined || authDispatch === undefined)
    throw new Error('useAuthContext must be used within AuthContextProvider.');

  return { authState, authDispatch };
};
