/* firebase documents, collections and sub collections */
export const contractorDocumentsCollection = 'contractor_documents';
export const contractorsCollection = 'contractors';
export const contractsSubCollection = 'contracts';
/* END - firebase documents, collections and sub collections */

/* ************************************** */
/* CLIENT SIDE AND SERVER SIDE VALIDATION */
/* ************************************** */
export function sanitizeEmail(email: string): string {
  return email.trim().toLowerCase();
}

export const getEmailRegexPattern = () => {
  return new RegExp(EmailRegexValidation.PATTERN);
};

export enum EmailPropertyValidation {
  MAX_LENGTH = 50,
}
export enum EmailRegexValidation {
  // actual value: ^[^\s@]+@[^\s@]+\.[^\s@]+$
  PATTERN = '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$', // Store regex pattern as a string
}

export enum AuthValidationMessages {
  REQUIRED = 'This field is required.',
  INCORRECT_FORMAT = 'Email is in wrong format.',
  MAX_LENGTH_OF_50 = 'Must not be greater than 50 characters.',
  PASSWORD_REQUIRED = 'Password is required',
  PASSWORD_MIN_LENGTH = 'Password must be at least 6 characters.',
  PASSWORD_MAX_LENGTH = 'Password must not be greater than 50 characters.',
  PASSWORDS_DO_NOT_MATCH = 'Your passwords do not match',
}

export enum PassPropertyValidation {
  MIN_LENGTH = 6,
  MAX_LENGTH = 50,
}
/* END - CLIENT SIDE AND SERVER SIDE VALIDATION */

/******** BEGIN EMAIL *********/
export const EmailRegexPattern = {
  PATTERN: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
};
/******** END EMAIL *********/

/******** BEGIN NAME *********/
export const NameRegexValidation = {
  // a-z, 0-9, '.,-&, space characters allowed, ONLY one of each special character
  PATTERN: /^(?!.*(['.,\-&]).*\1)[a-zA-Z0-9'.,\-& ]*$/,
};

export enum NamePropertyValidation {
  MAX_LENGTH = 50,
}

export enum NamePropertyValidationMessages {
  MAX_LENGTH_OF_50 = 'Must not be greater than 50 characters.',
  ONLY_SPECIAL_CHARACTERS = 'Only one of each special characters allowed: , . - & or apostrophe.',
}
/******** END NAME *********/

/******** BEGIN PHONE-NUMBER *********/
export const PhoneNumberRegexValidation = {
  PATTERN: /^\d{3}-\d{3}-\d{4}$/,
};

export enum PhoneNumberPropertyValidationMessages {
  INCORRECT_FORMAT = 'Required format: ###-###-####.',
}
/******** END PHONE-NUMBER *********/

/******** BEGIN STREET *********/
export const StreetRegexValidation = {
  // a-z, 0-9, '.,-&, #, space characters allowed
  PATTERN: /^[a-zA-Z0-9'.,\-&# ]*$/,
};

export enum StreetPropertyValidation {
  MAX_LENGTH = 50,
}

export enum StreetPropertyValidationMessages {
  ONLY_SPECIAL_CHARACTERS = 'Only special characters allowed: , . - & # or apostrophe.',
  MAX_LENGTH_OF_50 = 'Must not be greater than 50 characters.',
  NO_LEADING_OR_TRAILING_SPACES = 'No leading or trailing spaces allowed.',
}
/******** END STREET *********/

/******** BEGIN CITY *********/
export const CityRegexValidation = {
  // a-z, 0-9, '.,-&, space characters allowed
  PATTERN: /^[a-zA-Z0-9'.,\-& ]*$/,
};

export enum CityPropertyValidation {
  MAX_LENGTH = 50,
}

export enum CityPropertyValidationMessages {
  ONLY_SPECIAL_CHARACTERS = 'Only special characters allowed: , . - & or apostrophe.',
  MAX_LENGTH_OF_50 = 'Must not be greater than 50 characters.',
}
/******** END CITY *********/

/******** BEGIN STATE *********/
export const StateCodeRegexValidation = {
  // only letters allowed
  PATTERN: /^[A-Za-z]+$/,
};

export enum StatePropertyValidation {
  MAX_LENGTH = 30,
}

export enum StatePropertyValidationMessages {
  MAX_LENGTH_OF_30 = 'Must not be greater than 30 characters.',
  INCORRECT_FORMAT = 'State must contain only letters. No whitespaces.',
}
/******** END STATE *********/

/******** BEGIN ZIPCODE *********/
export const ZipCodeRegexValidation = {
  // format: ##### or #####-####
  PATTERN: /^\d{5}(-\d{4})?$/,
};

export enum ZipCodePropertyValidation {
  MAX_LENGTH = 10,
}

export enum ZipCodePropertyValidationMessages {
  MAX_LENGTH_OF_10 = 'Must not be greater than 10 characters.',
  INCORRECT_FORMAT = 'Format ##### or #####-####. No whitespaces.',
}
/******** END ZIPCODE *********/
