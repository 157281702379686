import { SubscriptionInfo } from '../types';

export const computeSubscriptionStatus = (subscription: SubscriptionInfo) => {
  if (subscription?.status === 'active') {
    // no cancel_at_period_end means the subscription is truly active
    if (!subscription.isCancelAtPeriodEnd) {
      return 'active';
    }

    // if cancel_at_period_end is true, check next bill date is present
    // cannot determine if the subscription is active or cancelled if value is null
    if (!subscription.current_period_end) {
      return 'unknown';
    }

    // Stripe uses UTC (Coordinated Universal Time) for all timestamps.
    // returns the current date and time in UTC format.
    const currentDate = new Date().toISOString();

    // if cancel_at_period_end is true, check if the current date is before the next bill date
    const currentPeriodEndDate = new Date(
      subscription.current_period_end
    ).toISOString();

    if (currentDate <= currentPeriodEndDate) {
      return 'cancelled_within_next_bill_date';
    }
  }

  return subscription?.status;
};
